import { BlogPageComponent } from './blog-page/blog-page.component';
import { GeekComponent } from './geek/geek.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotageekPageComponent } from './notageek-page/notageek-page.component';

const routes: Routes = [
  { path: '', component: NotageekPageComponent },
  { path: 'Blog/:page', component: BlogPageComponent },
  { path: 'Geek', component: GeekComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
