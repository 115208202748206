import {
  Component,
  OnInit,
  HostListener,
  Renderer2,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { MainServicesService } from '../services/main-services.service';

@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss'],
})
export class InstagramComponent implements OnInit {
  showArrows: boolean = true; // Initialize to true initially
  instagramData: any = [];

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private _services: MainServicesService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // Check screen size initially
    this.checkScreenWidth();

    this._services.getInstagram().subscribe((res: any) => {
      this.instagramData = res.instgram;
      // Trigger change detection
      this.cdr.detectChanges();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // Check screen width when the window is resized
    this.checkScreenWidth();
  }

  checkScreenWidth() {
    // Get the current screen width
    const screenWidth = window.innerWidth;

    // Set showArrows based on screen width
    this.showArrows = screenWidth < 1025;
  }

  isScreenLessThan1024px(): boolean {
    return this.showArrows;
  }
}
