<!-- Mat drawer for mobile version header -->
<mat-drawer-container hasBackdrop="true">
  <mat-drawer #mobileHeader mode="over" position="start">
    <div class="MobileSquare">
      <img src="assets/Images/headerLogo.png" routerLink="/" />
      <h1 (click)="goDown1(mobileHeader)">Firm</h1>
      <h1 (click)="goDown2(mobileHeader)">Team</h1>
      <h1 (click)="goDown3(mobileHeader)">Portfolio</h1>
      <h1 (click)="goDown6(mobileHeader)">Stories</h1>
      <!-- <h1 (click)="goDown7(mobileHeader)">News</h1> -->
      <h1 (click)="goDown4(mobileHeader)">Vacancies</h1>
      <h1 (click)="goDown5(mobileHeader)">Contact</h1>
    </div>
  </mat-drawer>
</mat-drawer-container>

<div class="header">
  <div class="square" *ngIf="mobile">
    <div class="col-4 text-left">
      <img
        src="assets/Images/more.png"
        class="more"
        (click)="openMobileHeader(mobileHeader)"
      />
    </div>
    <div class="col-4 text-center">
      <img src="assets/Images/headerLogo.png" class="logo" />
    </div>
  </div>
  <div class="square" *ngIf="!mobile">
    <img src="assets/Images/headerLogo.png" routerLink="/" />
    <h1 [style.font-size]="pageWidth / 83.47 + 'px'" (click)="goDown1()">
      Firm
    </h1>
    <h1 [style.font-size]="pageWidth / 83.47 + 'px'" (click)="goDown2()">
      Team
    </h1>
    <h1 [style.font-size]="pageWidth / 83.47 + 'px'" (click)="goDown3()">
      Portfolio
    </h1>
    <h1 [style.font-size]="pageWidth / 83.47 + 'px'" (click)="goDown6()">
      Stories
    </h1>
    <!-- <h1 [style.font-size]="pageWidth / 83.47 + 'px'" (click)="goDown7()">
      News
    </h1> -->
    <h1 [style.font-size]="pageWidth / 83.47 + 'px'">
      <a href="#vacancies">
        Vacancies
      </a>
    </h1>
    <h1 [style.font-size]="pageWidth / 83.47 + 'px'" (click)="goDown5()">
      Contact
    </h1>
  </div>
</div>
