import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainServicesService } from '../services/main-services.service';

@Component({
  selector: 'app-blog-page',
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.scss'],
})
export class BlogPageComponent implements OnInit {
  blogData;
  blogs: any;
  constructor(
    private router: ActivatedRoute,
    private _services: MainServicesService
  ) {}

  ngOnInit(): void {
    this.router.paramMap.subscribe((res: any) => {
      // this.blogData = JSON.parse(res.params.page);
      this._services.getBlog(res.params.page).subscribe((res: any) => {
        this.blogData = res;
      });
    });


    this._services.getBlogs().subscribe((res: any) => {
      this.blogs = res;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });
  }

  goToBlogPage(blog) {
    this.blogData = blog;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
