<mat-drawer-container hasBackdrop="true">
  <mat-drawer #about mode="over" position="end">
    <div class="person" *ngIf="personFlag">
      <div class="row h-100 p-0 m-0">
        <div class="col-md-4 col-sm-12 p-0 m-0 backgroundWhite">
          <img [src]="selectedMember?.picture" tabindex="0" />
        </div>
        <div class="col-md-8">
          <div class="popupContent">
            <h1 [style.font-size]="pageWidth / 87.27 + 'px'">
              {{ selectedMember?.position }}
            </h1>
            <h2 [style.font-size]="pageWidth / 53.33 + 'px'">
              {{ selectedMember?.name }}
            </h2>
            <h3
              [style.font-size]="pageWidth / 83.47 + 'px'"
              [innerHTML]="selectedMember?.description"
            ></h3>
          </div>
        </div>
      </div>
    </div>

    <div class="project" *ngIf="!personFlag">
      <div class="row p-0 m-0 height70">
        <div class="col-md-7 col-sm-12 p-0 m-0 backgroundWhite h-100">
          <div class="sqaure">
            <img [src]="selectedCompany?.picture" class="projectImg" />
            <a
              [href]="selectedCompany.linkedin"
              *ngIf="selectedCompany?.linkedin"
              class="linkedIn"
              target="_blank"
            >
              <img src="assets/Images/linkedIn.png" />
            </a>
            <a
              [href]="selectedCompany.website"
              class="web"
              *ngIf="selectedCompany?.website"
              target="_blank"
            >
              <img src="assets/Images/web.png" />
            </a>
            <h1 [style.font-size]="pageWidth / 96 + 'px'">Read their story</h1>
            <img src="assets/Images/blackLine.png" class="blackLine" />
            <h2
              [style.font-size]="pageWidth / 101 + 'px'"
              [innerHTML]="selectedCompany?.description"
            ></h2>
          </div>
        </div>
        <div class="col-md-5 col-sm-12 p-0 m-0 backgroundBlack h-100">
          <div class="sqaure">
            <h3 [style.font-size]="pageWidth / 106.6 + 'px'">Milestones</h3>
            <img src="assets/Images/blueLine.png" class="blueLine" />
            <h4
              [style.font-size]="pageWidth / 106.6 + 'px'"
              class="p-0 m-0"
              *ngFor="let mileStone of selectedCompany?.milestones"
            >
              {{ mileStone.name }} - {{ mileStone.value }}
            </h4>

            <br />
            <h3 [style.font-size]="pageWidth / 106.6 + 'px'">Team</h3>
            <img src="assets/Images/blueLine.png" class="blueLine" />
            <h4
              [style.font-size]="pageWidth / 106.6 + 'px'"
              class="p-0 m-0"
              *ngFor="let teamMember of selectedCompany?.team_members"
            >
              {{ teamMember?.name }} - {{ teamMember?.position }}
            </h4>
            <br />
            <h3 [style.font-size]="pageWidth / 106.6 + 'px'">Latest news</h3>
            <img src="assets/Images/blueLine.png" class="blueLine" />
            <a [href]="selectedCompany.link" target="_blank">
              <h4 [style.font-size]="pageWidth / 120 + 'px'" class="p-0 m-0">
                {{ selectedCompany?.lastest_news }}
              </h4>
            </a>
            <br />
            <h3
              [style.font-size]="pageWidth / 106.6 + 'px'"
              *ngIf="selectedCompany?.vacancies.length > 0"
            >
              Jobs
            </h3>
            <img
              *ngIf="selectedCompany?.vacancies.length > 0"
              src="assets/Images/blueLine.png"
              class="blueLine"
            />
            <h4
              [style.font-size]="pageWidth / 106.6 + 'px'"
              class="p-0 m-0"
              *ngFor="let vacancie of selectedCompany?.vacancies"
            >
              {{ vacancie?.position }}
            </h4>
          </div>
        </div>
      </div>
      <div class="row p-0 m-0 height30">
        <div class="col-6 p-0 m-0 h-100">
          <img [src]="selectedCompany?.left_picture" class="h-100" />
        </div>
        <div class="col-6 p-0 m-0 h-100">
          <img [src]="selectedCompany?.right_picture" class="h-100" />
        </div>
      </div>
    </div>
  </mat-drawer>
</mat-drawer-container>

<a class="changeWebSite" routerLink="/Geek">
  <img src="assets/Images/changeIcon.png" />
</a>

<app-header></app-header>

<div class="firstPart">
  <img src="assets/Images/firstText.png" class="w-75 firstTitle" />
  <swiper-container
    init="false"
    #swiper1
    slides-per-view="3.8"
    space-between="10"
    initial-slide="0"
    speed="500"
    loop="true"
    centered-slides="true"
    free-mode="false"
    navigation="true"
    pagination="true"
    autoplay="true"
  >
    <swiper-slide
      *ngFor="let founder of FoundersData; let i = index; trackBy: trackByFn"
      class="team-swiper-slide"
    >
      <div class="item-container">
        <div class="w-100 person-img-container">
          <img
            *ngFor="let member of founder.members; trackBy: trackByFn"
            [src]="member.photo"
            alt="Owner"
            class="containFit"
            [class.h-25]="founder.members.length === 4"
            [class.h-50]="founder.members.length === 2"
            [class.h-100]="founder.members.length === 1"
          />
        </div>
        <div class="w-50 founderLogo-container">
          <img
            [src]="founder.logo"
            alt="Company Logo"
            class="containFit img-fluid"
          />
        </div>
        <!-- <h2>{{founder.name}}</h2> -->
        <!-- <p>{{founder.members[0]?.name}}</p> -->
      </div>
    </swiper-slide>
  </swiper-container>
  <h1 [style.font-size]="pageWidth / 28.65 + 'px'" id="target1">
    Empowering entrepreneurs to improve people’s lives at scale
  </h1>
  <img src="assets/Images/ourCir.png" class="w-50 circle mb-5" />
</div>

<div class="fifthPart" id="target4">
  <div class="row m-0 whiteBack">
    <div class="col-12 order-1 order-md-0 col-md-7 p-0">
      <img src="assets/Images/imgCollected.png" class="collectedImg" />
    </div>
    <div class="col-12 order-0 order-md-1 col-md-5 p-0">
      <h1 [style.font-size]="pageWidth / 58.18 + 'px'">
        Pioneering Venture Capital in Egypt
      </h1>
      <h2 [style.font-size]="pageWidth / 106.66 + 'px'">
        Algebra Ventures is a tech-focused venture capital firm that partners
        with bold and resilient founders building transformative companies in
        Egypt, and across the African continent. Algebra raised its first $54M
        fund in 2016 and its second $100M fund in 2022 from a diverse investor
        base including international DFIs, governmental institutions, corporate
        investors and family offices.<br />
        <br />Among the most experienced venture investors in the Middle East
        and Africa, Algebra has backed some of the most notable startups in
        Egypt that have become category leaders in their markets. Algebra
        Ventures is a multi-stage investor supporting founders from seed stage
        to Series B. More than just a source of capital, Algebra acts as a
        comprehensive growth platform for startups, providing strategic
        guidance, access to co-investors, and talent development. Algebra’s
        leadership team has invested in over 100 companies in the past 15 years.
      </h2>
    </div>
  </div>
</div>

<div class="secondPart">
  <img src="assets/Images/DNA.jpg" class="main_img" />
  <div class="square">
    <div class="row">
      <img src="assets/Images/AreaOfInvesmets.png" class="w-50 Invesmets" />
    </div>

    <div class="row borderTop">
      <div class="col-6 mt-4">
        <!-- <h2 [style.font-size]="pageWidth / 30.47 + 'px'">01</h2> -->
        <h3 [style.font-size]="pageWidth / 58.18 + 'px'">Egypt</h3>
        <h4 [style.font-size]="pageWidth / 106.66 + 'px'" class="mt-3">
          Grounded in our continued belief in Egyptian tech entrepreneurs,
          Algebra Ventures doubled down on its home market with the launch of
          its $90M second fund, while still opportunistically investing in the
          rest of the MEA region.
        </h4>
      </div>
      <div class="col-6 borderLeft mt-4">
        <!-- <h2 [style.font-size]="pageWidth / 30.47 + 'px'">02</h2> -->
        <h3 [style.font-size]="pageWidth / 58.18 + 'px'">Founders</h3>
        <h4 [style.font-size]="pageWidth / 106.66 + 'px'" class="mt-3">
          We are dedicated to our founders by providing capital, assisting with
          strategy and operations, as well as helping build star teams to ensure
          that the most qualified and ambitious individuals continue to join
          Algebra’s portfolio.
        </h4>
      </div>
    </div>

    <div class="row borderTop mt-4 mb-4">
      <div class="col-6 mt-4">
        <!-- <h2 [style.font-size]="pageWidth / 30.47 + 'px'">01</h2> -->
        <h3 [style.font-size]="pageWidth / 58.18 + 'px'">Impact</h3>
        <h4 [style.font-size]="pageWidth / 106.66 + 'px'" class="mt-3">
          We partner with founders who are transforming industries, addressing
          long-standing problems that only technology can provide solutions for,
          and directly improving the lives of millions.
        </h4>
      </div>
      <div class="col-6 borderLeft mt-4">
        <!-- <h2 [style.font-size]="pageWidth / 30.47 + 'px'">02</h2> -->
        <h3 [style.font-size]="pageWidth / 58.18 + 'px'">Network</h3>
        <h4 [style.font-size]="pageWidth / 106.66 + 'px'" class="mt-3">
          We engage with the strongest co-investors with EM experience to bring
          the right value to our startups and connect our portfolio with
          industry partners to support growth and future exit.
        </h4>
      </div>
    </div>
  </div>
</div>

<div class="thirdPart-1" id="target2">
  <img src="assets/Images/partners.png" class="team" />
  <div class="square">
    <div class="row">
      <ng-container *ngFor="let member of members; let i = index">
        <div
          *ngIf="!member.is_team"
          class="col-6 col-md-2"
          (click)="goToMember(about, member)"
        >
          <img [src]="member?.picture" />
          <h2 [style.font-size]="pageWidth / 70 + 'px'">
            {{ member?.name }}
          </h2>
          <h3 [style.font-size]="pageWidth / 100 + 'px'">
            {{ member?.position }}
          </h3>
          <!-- <h4
          [style.font-size]="pageWidth / 137.14 + 'px'"
          (click)="goToMember(about, member)"
        >
          View Bio
        </h4> -->
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="thirdPart" id="target2">
  <img src="assets/Images/team.png" class="team" />
  <div class="square">
    <div class="row">
      <ng-container *ngFor="let member of members; let i = index">
        <div
          *ngIf="member.is_team"
          class="col-6 col-md-2"
          (click)="goToMember(about, member)"
        >
          <img [src]="member?.picture" />
          <h2 [style.font-size]="pageWidth / 70 + 'px'">
            {{ member?.name }}
          </h2>
          <h3 [style.font-size]="pageWidth / 100 + 'px'">
            {{ member?.position }}
          </h3>
          <!-- <h4
          [style.font-size]="pageWidth / 137.14 + 'px'"
          (click)="goToMember(about, member)"
        >
          View Bio
        </h4> -->
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="forthPart" id="target3">
  <img src="assets/Images/Portoflio.png" class="portfoilo" />
  <div class="square">
    <div class="row">
      <div class="h1" [style.font-size]="pageWidth / 120 + 'px'">
        <span
          id="firstChoiceProject"
          (click)="filterProjects('all', 'firstChoiceProject')"
          class="active"
          >All</span
        >
        <span
          id="secondChoiceProject"
          (click)="filterProjects('one', 'secondChoiceProject')"
          >Fund 1</span
        >
        <span
          id="thirdChoiceProject"
          (click)="filterProjects('two', 'thirdChoiceProject')"
          >Fund 2</span
        >
      </div>
    </div>

    <div class="row m-0">
      <div
        class="manualCard"
        (click)="goToProject(about, company)"
        *ngFor="let company of filterCompanies"
      >
        <img [src]="company?.picture" />
        <h2 [style.font-size]="pageWidth / 120 + 'px'">
          {{ company?.small_description }}
        </h2>
      </div>
    </div>
  </div>
</div>

<div class="formulas-bg">
  <div class="text-center text-container">
    <img src="assets/Images/formulas.svg" alt="formulas text" />
  </div>
  <div
    id="carouselExampleIndicators"
    class="carousel slide"
    data-bs-interval="false"
    #carouselRef
  >
    <div class="carousel-indicators gap-3">
      <button
        *ngFor="let data of formulasData; let i = index"
        type="button"
        [attr.data-bs-target]="'#carouselExampleIndicators'"
        [attr.data-bs-slide-to]="i"
        [ngClass]="{ active: i === activeSlideIndex }"
        aria-label="Slide {{ i + 1 }}"
        class="indicators"
        (click)="onSlideChange(i)"
      ></button>
    </div>
    <div class="carousel-inner mt-5 pt-3">
      <div
        class="carousel-item"
        *ngFor="let data of formulasData; let i = index"
        [class.active]="i === activeSlideIndex"
      >
        <div class="">
          <div
            class="row justify-content-center flex-lg-row gap-lg-4 mobile-column"
          >
            <div
              class="p-0 d-flex justify-content-sm-center justify-content-end video-container"
            >
              <div class="video-player" *ngIf="data.video && !data.url">
                <video
                  width="100%"
                  height="100%"
                  class="w-100 h-100"
                  #videoElement
                  (click)="onVideoClick(i)"
                  controls
                >
                  <source src="{{ data.video }}" type="" />
                </video>
              </div>

              <div
                class="iframe-container"
                *ngIf="data.url || (data.url && data.video) ? data.url : ''"
              >
                <iframe
                  width="100%"
                  height="100%"
                  [src]="extractVideoId(data.url)"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div class="caption-row-container p-0">
              <div
                class="caption-container text-white h-100 d-flex flex-column mt-1"
              >
                <h2>{{ data.title }}</h2>
                <p>{{ data.sub_title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <img
      src="assets/Images/rightArrow.svg"
      class="carousel-control-prev left-arrow"
      type="button"
      data-bs-target="#carouselExampleIndicators"
      data-bs-slide="prev"
      alt="left arrow"
      (click)="onPrevSlide()"
    />
    <img
      src="assets/Images/rightArrow.svg"
      class="carousel-control-next right-arrow"
      type="button"
      data-bs-target="#carouselExampleIndicators"
      data-bs-slide="next"
      alt="rightArrow"
      (click)="onNextSlide()"
    />
  </div>
</div>

<div class="blogPart" id="blog">
  <div class="row">
    <img src="assets/Images/stories.png" style="width: 30%" />
  </div>

  <div class="blogCards row">
    <div class="card p-0" *ngFor="let oneBlog of blogs">
      <div (click)="goToBlogPage(oneBlog)">
        <div class="row m-0 p-0 positionRelative">
          <img [src]="oneBlog?.picture" class="p-0" />
          <div class="dateCard">
            <h1>{{ oneBlog?.created | date : "d" }}</h1>
            <h2>{{ oneBlog?.created | date : "MMM" }}</h2>
          </div>
        </div>

        <h3>
          {{ oneBlog?.title }}
        </h3>

        <h4 [innerHTML]="oneBlog?.short_description"></h4>
      </div>
    </div>
  </div>

  <!-- newsletter sub btn  -->
</div>

<div class="subscription-section fixed-subscription-sec-bottom">
  <div class="sticky-container">
    <p class="desktop-only">
      Subscribe to our newsletter to get the latest news.
    </p>
    <p class="mobile-only">Subscribe to our newsletter</p>
    <button class="subscribe-newsletter" (click)="RedirectToSubscribe()">
      <span>Subscribe</span>
    </button>
    <img
      src="assets/Images/closeIcon.svg"
      alt="close-icon"
      (click)="hideStickySection($event)"
    />
  </div>
</div>

<div class="vacancies">
  <img src="assets/Images/Vacancies.png" id="vacancies" />
  <div class="square">
    <div class="row">
      <h3 [style.font-size]="pageWidth / 120 + 'px'">
        <span
          id="firstChoice"
          class="active"
          (click)="selectType('firstChoice')"
          >All</span
        >

        <span id="thirdChoice" (click)="selectType('thirdChoice')">
          Portfolio vacancies
        </span>
        <span id="secondChoice" (click)="selectType('secondChoice')">
          Algebra vacancies
        </span>
      </h3>
    </div>
    <div class="row mt-4" *ngFor="let job of filterVacancies">
      <h4 [style.font-size]="pageWidth / 120 + 'px'" *ngIf="job.is_ours">
        Algebra vacancies
      </h4>
      <h4 [style.font-size]="pageWidth / 120 + 'px'" *ngIf="!job.is_ours">
        Portfolio vacancies
      </h4>
      <h5 [style.font-size]="pageWidth / 73.84 + 'px'" *ngIf="job.is_ours">
        <a [href]="job.link" target="_blank">
          {{ job.position }}
        </a>
      </h5>
      <a *ngIf="!job.is_ours">
        <h5 [style.font-size]="pageWidth / 73.84 + 'px'" *ngIf="!job.is_ours">
          {{ job.position }} {{ "- " + job?.company }}
        </h5>
      </a>
      <h6 [style.font-size]="pageWidth / 73.84 + 'px'">
        {{ job.description }}
      </h6>
    </div>

    <div class="row email">
      <h6 [style.font-size]="pageWidth / 73.84 + 'px'">
        Email us at: people@algebraventures.com
      </h6>
    </div>
  </div>
</div>

<app-instagram></app-instagram>

<div class="sixthPart" id="target5">
  <div class="row m-0">
    <img src="assets/Images/Contact.png" />
  </div>
  <div class="square">
    <div class="row m-0 w-100">
      <div class="col-sm-12 col-md-6 pr-2">
        <h1>Get in Touch</h1>
        <h3
          [style.font-size]="pageWidth / 80 + 'px'"
          class="mt-4"
          id="par"
        ></h3>

        <!-- <h2 [style.font-size]="pageWidth / 80 + 'px'" class="mt-4">Address</h2> -->
        <h3>
          <img src="assets/Images/location.svg" />
          4 Omarat Al-Yamani Street, Zamalek, Cairo, Egypt
        </h3>

        <!-- <h2 [style.font-size]="pageWidth / 80 + 'px'" class="mt-4">Email</h2> -->
        <h3>
          <img src="assets/Images/Email.svg" />
          info@algebraventures.com
        </h3>

        <!-- <h2 [style.font-size]="pageWidth / 80 + 'px'" class="mt-4"> -->
        <a href="https://www.linkedin.com/company/algebraventures">
          <img src="assets/Images/linkedin.svg" />LinkedIn</a
        >
        <!-- </h2> -->
      </div>
      <div class="col-sm-12 col-md-6">
        <label for="fname" class="mt-4"> Name <span>(optional) </span> </label>
        <input
          [(ngModel)]="nameForm"
          type="text"
          id="fname"
          name="fname"
          class="inputBorder"
        /><br /><br />

        <label for="email" [style.font-size]="pageWidth / 80 + 'px'">
          Email address <span>(optional) </span>
        </label>
        <input
          [(ngModel)]="emailForm"
          type="text"
          id="email"
          name="email"
          class="inputBorder"
        /><br /><br />

        <label for="phone" [style.font-size]="pageWidth / 80 + 'px'">
          Phone number <span>(optional) </span>
        </label>
        <input
          [(ngModel)]="phoneForm"
          type="text"
          id="phone"
          name="phone"
          class="inputBorder"
        /><br /><br />

        <label for="subject" [style.font-size]="pageWidth / 80 + 'px'">
          Subject
        </label>
        <select name="reminder" class="inputBorder" [(ngModel)]="subjectForm">
          <option selected hidden value=""></option>
          <option value="Submit a Pitch">Submit a Pitch</option>
          <option value="Job Inquiry">Job Inquiry</option>
          <option value="Portfolio Contact">Portfolio Contact</option>
          <option value="Report a Grievance">Report a Grievance</option>
          <option value="Other">Other</option>
        </select>

        <label
          for="attachment"
          [style.font-size]="pageWidth / 80 + 'px'"
          class="mt-4"
        >
          Attachment
        </label>
        <input
          type="file"
          id="attachment"
          name="attachment"
          #attachment
          (change)="getAttach(attachment.files)"
        />

        <label
          for="message"
          [style.font-size]="pageWidth / 80 + 'px'"
          class="mt-4"
        >
          Message
        </label>
        <textarea
          id="message"
          name="message"
          rows="4"
          class="inputBorder"
          [(ngModel)]="messageForm"
        >
        </textarea>

        <button (click)="contactUs()" [disabled]="isLoading">
          <div *ngIf="!isLoading">Send message</div>
          <div class="spin" *ngIf="isLoading"></div>
        </button>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
