// prev.directive.ts
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPrev]',
})
export class PrevDirective {
  constructor(private el: ElementRef) {}

  @HostListener('click')
  prevFunc() {
    const elm = this.el.nativeElement.parentElement.parentElement.children[0];
    const items = elm.getElementsByClassName('slider');

    const itemIndexToFadeOutOne = 1;
    const itemIndexToFadeOutTwo = 2;
    const itemIndexToFadeOutThree = 3;
    const itemIndexToFadeOutFour = 4;

    const screen_width = screen.width;

    let itemToFadeOut;

    if (screen_width >= 1440) {
      itemToFadeOut = items[itemIndexToFadeOutFour];
    } else if (screen_width >= 1024) {
      itemToFadeOut = items[itemIndexToFadeOutThree];
    } else if (screen_width >= 768) {
      itemToFadeOut = items[itemIndexToFadeOutTwo];
    } else if (screen_width <= 425) {
      itemToFadeOut = items[itemIndexToFadeOutOne];
    } else if (screen_width >= 1200) {
      itemToFadeOut = items[itemIndexToFadeOutFour];
    }

    if (itemToFadeOut) {
      itemToFadeOut.classList.add('fade-out');

      // Move the first item to the end of the list
      const firstItem = items[0];
      elm.append(firstItem);

      // Remove 'fade-out' class after a delay
      setTimeout(() => {
        itemToFadeOut.classList.remove('fade-out');
      }, 500);
    }
  }
}
