import { MainServicesService } from './../services/main-services.service';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { trigger, style, animate, transition } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-notageek-page',
  templateUrl: './notageek-page.component.html',
  styleUrls: ['./notageek-page.component.scss'],
  animations: [
    trigger('slideAnimation', [
      transition(':increment', [
        style({ transform: 'translateX(100%)' }),
        animate('1000ms ease-in-out', style({ transform: 'translateX(0)' })),
      ]),
      transition(':decrement', [
        style({ transform: 'translateX(-100%)' }),
        animate('1000ms ease-in-out', style({ transform: 'translateX(0)' })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush, // Add OnPush change detection strategy
})
export class NotageekPageComponent implements OnInit {
  @ViewChild('myCarousel', { static: true }) myCarousel: ElementRef;
  activeSlide: number = 1;

  @ViewChild('carouselRef', { static: false }) carouselRef: ElementRef;
  pageHeight;
  pageWidth;
  personFlag = true;
  companies = [];
  filterCompanies = [];
  members = [];
  vacancies = [];
  filterVacancies = [];
  news = [];
  blogs = [];
  selectedCompany;
  selectedMember;
  mobile = false;
  nameForm = '';
  emailForm = '';
  phoneForm = '';
  subjectForm = '';
  messageForm = '';
  attachmentForm;
  isLoading: boolean = false;
  activeSlideIndex = 0;
  slideAnimation: number = 0;
  youtubeVideo;
  videoStates: boolean[] = [];
  showControls: boolean[] = [];
  hideProgress: boolean = false;
  showButton: boolean[] = [];
  formulasData: any = [];
  FoundersData: any = [];
  @ViewChildren('videoElement') videoElements: QueryList<ElementRef>;
  @ViewChild('swiper1') swiper1!: ElementRef<any>;


  constructor(
    private _services: MainServicesService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.pageHeight = window.innerHeight;
    this.pageWidth = window.innerWidth;

    if (this.pageWidth < 1000) {
      this.mobile = true;
    }

    this._services.getBlogs().subscribe((res: any) => {
      this.blogs = res;
    });

    this._services.getNews().subscribe((res: any) => {
      this.news = res;
    });

    this._services.getFormulas().subscribe((res: any) => {
      this.formulasData = res.formulas;
    });

    this._services.getMembers().subscribe((res: any) => {
      this.members = res;
      this.cdr.detectChanges();
    });

    this._services.getVacancies().subscribe((res: any) => {
      this.vacancies = res;
      this.filterVacancies = res;
    });

    this._services.getCompanies().subscribe((res: any) => {
      this.companies = res;
      this.filterCompanies = res;
      // this.filterCompanies.push(...res);

      this.companies.sort(function (a, b) {
        return a.priority - b.priority;
      });
      this.cdr.detectChanges();
    });

    this._services.getParagraph().subscribe((res: any) => {
      if (res.length > 0) {
        document.getElementById('par').innerHTML = res[0].paragraph;
      }
    });

    this._services.getFounders().subscribe((res: any) => {
      // this.FoundersData = this.chunkArray(res, 3);
      this.FoundersData = res;
      this.cdr.markForCheck(); // Trigger change detection manually
      this.initSwiper();
    });

    
    }
    initSwiper() {      
      const swiperParams = {
        updateOnWindowResize: true,
        pagination:{
          dynamicBullets:true,
          dynamicMainBullets:4,
        },
        breakpoints: {
          100: {
            slidesPerView: 1.3,
          },
          640: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 3.8,
          },
        },
      };
      
      Object.assign(this.swiper1.nativeElement, swiperParams);
      setTimeout(() => {
        this.swiper1.nativeElement.initialize();
      }, 500);
  
    }

  
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.addHundredHeightClass();
  }
  addHundredHeightClass() {
    const pageWidth = window.innerWidth;
    if (pageWidth >= 768) {
      this.renderer.addClass(this.el.nativeElement, 'h-100');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'h-100');
    }
  }
  // this function to stop the video when i move next or back
  onSlideChange(index: number) {
    this.activeSlideIndex = index;

    // Pause all videos when the slide changes
    this.videoStates.forEach((state, index) => {});
  }

  onNextSlide() {
    if (this.activeSlideIndex < this.formulasData.length - 1) {
      this.activeSlideIndex++;
    }
  }
  onPrevSlide() {
    if (this.activeSlideIndex > 0) {
      this.activeSlideIndex--;
    }
  }
  // Function to handle visibility buttons
  toggleButtonVisibility(index: number) {
    this.showButton[index] = true;
    setTimeout(() => {
      this.showButton[index] = false;
    }, 500);
  }

  percentage: any[] = [];

  ngOnInit(): void {
    // ------------
  }

  // trackBy function for ngFor
  trackByFn(index, item) {
    return item.id; // Replace 'item.id' with a unique identifier for your items
  }

  // Function to chunk the array into groups of specified size
  chunkArray(array: any[], size: number): any[] {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }

  changeSlide(slideNumber: number) {
    this.activeSlide = slideNumber;
    // this.updateIndicator(); // Update the indicator immediately
  }
  filterProjects(type, id) {
    document.getElementById('firstChoiceProject').classList.remove('active');
    document.getElementById('secondChoiceProject').classList.remove('active');
    document.getElementById('thirdChoiceProject').classList.remove('active');
    document.getElementById(id).classList.add('active');
    this.filterCompanies = this.companies.filter((item) => {
      if (type == 'one') {
        return item.fund == 1 ? item : null;
      } else if (type == 'two') {
        return item.fund == 2 ? item : null;
      }
      return item;
    });
  }

  extractVideoId(url: string) {
    // Extract video ID logic
    let videoId = url?.split('v=')[1];
    videoId = videoId?.split('&')[0];
    let tempYoutubeUrl = `https://www.youtube.com/embed/${videoId}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(tempYoutubeUrl);
  }

  goToProject(htmlElement, company) {
    this.selectedCompany = company;
    this.personFlag = false;
    htmlElement.toggle();
  }

  goToMember(htmlElement, member) {
    this.personFlag = true;
    this.selectedMember = member;
    htmlElement.toggle();
  }

  selectType(id) {
    document.getElementById('firstChoice').classList.remove('active');
    document.getElementById('secondChoice').classList.remove('active');
    document.getElementById('thirdChoice').classList.remove('active');
    document.getElementById(id).classList.add('active');
    if (id == 'firstChoice') {
      this.filterVacancies = this.vacancies;
    } else if (id == 'secondChoice') {
      this.filterVacancies = this.vacancies.filter((item) => {
        return item.is_ours;
      });
    } else {
      this.filterVacancies = this.vacancies.filter((item) => {
        return !item.is_ours;
      });
    }
  }

  getAttach(file: FileList) {
    this.attachmentForm = file.item(0);
  }

  contactUs() {
    this.isLoading = true;
    const formData = new FormData();
    formData.append('attachment', this.attachmentForm);
    formData.append('name', this.nameForm);
    formData.append('email', this.emailForm);
    formData.append('phone_number', this.phoneForm);
    formData.append('subject', this.subjectForm);
    formData.append('message', this.messageForm);

    this._services.contactUs(formData).subscribe(
      (res: any) => {
        this.isLoading = false;
        this.emailForm = '';
        this.nameForm = '';
        this.phoneForm = '';
        this.subjectForm = '';
        this.messageForm = '';
        this.attachmentForm = '';
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  goToBlogPage(page) {
    this.router.navigate(['/Blog', JSON.stringify(page.id)]);
  }

  RedirectToSubscribe() {
    window.location.href =
      'https://algebraventures.us1.list-manage.com/subscribe?u=d1654bd2e05e2eca77483da64&id=51a92cfa0e';
  }
  hideStickySection(e) {
    let eleToHide = e.target.parentElement.parentElement;
    eleToHide.classList.add('zero-opacity');
    let geekStickyIcon = document.querySelector(
      'a.changeWebSite'
    ) as HTMLElement;
    geekStickyIcon?.classList?.add('original-top-space');
    setTimeout(() => {
      eleToHide.style.display = 'none';
    }, 1500);
  }
}
