import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MainServicesService {
  api = 'https://algebra.bit68.com/api/';

  constructor(private http: HttpClient) {}

  // addItemToCart(prodId, QTY, sizeID) {
  //   return this.http.post(`${this.api}/api/cart/`, {
  //     product_id: prodId,
  //     qty: QTY,
  //     product_property: sizeID
  //   });
  // }

  getFounders() {
    return this.http.get(`${this.api}founder/`);
  }
  getCompanies() {
    return this.http.get(`${this.api}companies/`);
  }

  getMembers() {
    return this.http.get(`${this.api}members/`);
  }

  getVacancies() {
    return this.http.get(`${this.api}vacancies/`);
  }

  contactUs(form) {
    return this.http.post(`${this.api}contact-us/`, form, {});
  }

  getParagraph() {
    return this.http.get(`${this.api}paragraph/`);
  }

  getNews() {
    return this.http.get(`${this.api}news/`);
  }

  getBlogs() {
    return this.http.get(`${this.api}blogs/`);
  }

  getBlog(id: any) {
    return this.http.get(`${this.api}blogs/${id}/`);
  }

  getFirms() {
    return this.http.get(`${this.api}firms/`);
  }
  getFormulas() {
    return this.http.get(`${this.api}formulas/`);
  }

  getInstagram() {
    return this.http.get(`${this.api}instgram/`);
  }
}
