// next.directive.ts
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNext]',
})
export class NextDirective {
  constructor(private el: ElementRef) {}

  @HostListener('click')
  nextFunc() {
    const elm = this.el.nativeElement.parentElement.parentElement.children[0];
    const items = elm.getElementsByClassName('slider');

    // Loop through all items and add 'fade-out' class to all except the first 4
    for (let i = 0; i < items.length; i++) {
      if (i < 4) {
        continue; // Skip the first 4 items
      }
      const item = items[i];
      item.classList.add('fade-out');
    }

    setTimeout(() => {
      const lastItem = items[items.length - 1];
      elm.prepend(lastItem);
      setTimeout(() => {
        lastItem.classList.remove('fade-out');
      }, 100);
    }, 100);
  }
}
