<app-header></app-header>
<div class="width70">
  <h1 class="mb-5">{{ blogData?.title }}</h1>
  <img [src]="blogData?.inner_picture" class="w-100" />
  <h2 class="my-2">Written by: {{ blogData?.author }}</h2>
  <div class="row">
    <div class="col-3 col-md-1">
      <div class="dateCard">
        <h1>{{ blogData.created | date: "d" }}</h1>
        <h2>{{ blogData.created | date: "MMM" }}</h2>
      </div>
    </div>
    <div class="col-9 col-md-10">
      <h3 [innerHTML]="blogData?.long_description"></h3>
    </div>
  </div>
</div>

<div class="backgroundColor">
  <div class="blogPart width70">
    <div class="row">
      <img src="assets/Images/stories.png" style="width:30%" />
    </div>

    <div class="blogCards row">
      <div class="card p-0" *ngFor="let blog of blogs">
        <div (click)="goToBlogPage(blog)">
          <div class="row m-0 p-0 positionRelative">
            <img [src]="blog.picture" class="p-0 m-0" />
            <div class="dateCard">
              <h1>{{ blog.created | date: "d" }}</h1>
              <h2>{{ blog.created | date: "MMM" }}</h2>
            </div>
          </div>

          <h3>
            {{ blog.title }}
          </h3>

          <h4 [innerHTML]="blog.short_description"></h4>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
