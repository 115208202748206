<div class="background" (window:resize)="onResize($event)">
  <!-- toastr -->
  <div class="toast align-items-center customToast" id="myToast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="d-flex">
      <div class="toast-body">Error, try again</div>
      <button type="button" class="btn-close me-2 m-auto btn-close-white" data-bs-dismiss="toast" aria-label="Close"
        (click)="closeToast()"></button>
    </div>
  </div>

  <div class="mainSquare">
    <form (submit)="(false)" (keydown)="keyDownFunction($event)">
      <div class="row">
        <h1 [style.font-size]="pageWidth / 96.25 + 'px'">
          Welcome to Algebra Ventures 2.00
        </h1>
      </div>
      <div class="row team">
        <h2 [style.font-size]="pageWidth / 96.25 + 'px'" class="firstLine mb-2"></h2>
        <h4 [style.font-size]="pageWidth / 96.25 + 'px'" class="backHint mb-2" *ngIf="!isWriteHint">
          Type "back" to return.
        </h4>
        <h2 [style.font-size]="pageWidth / 83.6 + 'px'" class="hTwo">
          <span class="secondLinePartOne"></span>
          <span class="secondLinePartTwo"></span>
          <span class="secondLinePartThree"></span>
          <input id="typeInput" [(ngModel)]="inputString[0]" name="inputString" [readonly]="inputDisableFlag[0]" />
          <br />
          <br />

          <div id="contactForm"></div>
          <div id="navs"></div>

          <div [style.font-size]="pageWidth / 83.6 + 'px'" class="team">
            <span class="nav0"></span>
            <span class="nav1"></span>
            <div class="contact_case">
              <br />
              <span class="map_span"></span>
              <br class="d-block d-md-none" />
              <a href="https://goo.gl/maps/h2WiJ11H4VdaQf8DA" class="map_link" target="_blank"></a>
              <br />
              <br />
            </div>
            <span class="nav2"></span>
            <span class="nav3"></span>
            <span class="nav4"></span>
            <span class="nav5"></span>
            <span class="nav6"></span>
            <span class="nav7"></span>
            <span class="nav8"></span>
            <span class="nav9"></span>
            <span class="nav10"></span>
            <span class="nav11"></span>
          </div>

          <div [style.font-size]="pageWidth / 83.6 + 'px'" class="team">
            <ng-container *ngIf="companies && companies.length > 0">
              <div class="row">
                <div class="col-md-6 team">
                  <span *ngFor="let i of companies1; let ind = index" class="team{{ ind }}"></span>
                </div>

                <div class="col-md-6 team">
                  <span *ngFor="let i of companies2; let ind = index" class="team{{ ind + companies1.length }}"></span>
                </div>

                <!-- <div class="col-sm-6 col-md-6 team">
                <span class="team0"></span>
                <span class="team1"></span>
                <span class="team2"></span>
                <span class="team3"></span>
                <span class="team4"></span>
                <span class="team5"></span>
                <span class="team6"></span>
                <span class="team7"></span>
                <span class="team8"></span>
              </div>
              <div class="col-sm-6 col-md team">
                <span class="team9"></span>
                <span class="team10"></span>
                <span class="team11"></span>
                <span class="team12"></span>
                <span class="team13"></span>
                <span class="team14"></span>
                <span class="team15"></span>
                <span class="team16"></span>
                <span class="team17"></span>
                <span class="team18"></span>
                <span class="team19"></span>
                <span class="team20"></span>
              </div> -->
                <!-- <div class="col-sm-6 col-md team">
                <span class="team14"></span>
                <span class="team15"></span>
                <span class="team16"></span>
                <span class="team17"></span>
                <span class="team18"></span>
                <span class="team19"></span>
                <span class="team20"></span>
              </div> -->
                <!-- <div class="col-sm-6 col-md team">
                <span class="team21"></span>
                <span class="team22"></span>
                <span class="team23"></span>
                <span class="team24"></span>
                <span class="team25"></span>
                <span class="team26"></span>
                <span class="team27"></span>
              </div> -->
              </div>
            </ng-container>
          </div>

          <div [style.font-size]="pageWidth / 83.6 + 'px'" class="team">
            <span class="navOne"></span>
            <span class="navTwo"></span>
            <span class="navThree"></span>
            <span class="navFour"></span>
            <span class="navFive"></span>
            <span class="navSix"></span>
          </div>
          <br />
          <span class="navAnswerOne"></span>
          <span class="navAnswerTwo"></span>
          <input id="typeInput1" [(ngModel)]="inputString[1]" name="inputString" [readonly]="inputDisableFlag[1]" />
        </h2>
      </div>
    </form>
  </div>

  <div id="drawerFromBottom" class="drawerFromBottom">
    <div class="closeArrow" (click)="closeFirmPop()">
      <img src="assets/Images/closeArrow.png" />
    </div>
    <div class="row firm_menu_row" *ngFor="let firm of firms">
      <h1 [style.font-size]="pageWidth / 96 + 'px'" class="mt-2">
        {{ firm.title }}
      </h1>
      <h2 [style.font-size]="pageWidth / 120 + 'px'">
        {{ firm.subtitle }}
      </h2>
      <h4 [style.font-size]="pageWidth / 120 + 'px'" [innerHTML]="sanitized.bypassSecurityTrustHtml(firm.description)"></h4>
    </div>
  </div>

  <div id="selectFile" class="drawerFromBottom">
    <div class="closeArrow" (click)="closeSelectFile()">
      <img src="assets/Images/closeArrow.png" />
    </div>
    <label for="attachment" [style.font-size]="pageWidth / 80 + 'px'" class="mt-4 d-block">
      Attachment
    </label>
    <input type="file" id="attachment" name="attachment" #attachment (change)="getAttach(attachment.files)" />
  </div>

  <div id="selectedMember" class="drawerFromBottom">
    <div class="closeArrow" (click)="closeTeamPop()">
      <img src="assets/Images/closeArrow.png" />
    </div>
    <div class="row mt-3 mb-3">
      <div class="col-4 whiteBackGround">
        <img [src]="selectedMembers?.picture" />
      </div>
      <div class="col-8">
        <div class="row">
          <h2 [style.font-size]="pageWidth / 61.93 + 'px'">
            {{ selectedMembers?.name }}
          </h2>
          <h2 [style.font-size]="pageWidth / 101.05 + 'px'">
            {{ selectedMembers?.position }}
          </h2>
        </div>
        <div class="row">
          <h3 [style.font-size]="pageWidth / 120 + 'px'" [innerHTML]="selectedMembers?.description"></h3>
        </div>
      </div>
    </div>
  </div>

  <div id="selectedCompany" class="drawerFromBottom">
    <div class="closeArrow" (click)="closeCompanyPop()">
      <img src="assets/Images/closeArrow.png" />
    </div>

    <div class="row mb-2">
      <div class="col">
        <img [src]="selectedCompany?.left_picture" class="projectImgs" />
      </div>
      <div class="col">
        <img [src]="selectedCompany?.right_picture" class="projectImgs" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 [style.font-size]="pageWidth / 101 + 'px'">
          <a [href]="selectedCompany.website" *ngIf="selectedCompany?.website" class="web" target="_blank">
            Web
          </a>
          <a [href]="selectedCompany.linkedin" *ngIf="selectedCompany?.linkedin" class="linkedIn" target="_blank">
            Linkedin
          </a>
        </h1>
        <h2 [style.font-size]="pageWidth / 46.82 + 'px'">
          {{ selectedCompany?.name }}
        </h2>
      </div>
      <div class="col">
        <h2 [style.font-size]="pageWidth / 101 + 'px'">Milestones</h2>
        <h4 [style.font-size]="pageWidth / 106.6 + 'px'" class="p-0 m-0"
          *ngFor="let mileStone of selectedCompany?.milestones">
          {{ mileStone.name }} - {{ mileStone.value }}
        </h4>
      </div>
      <div class="col">
        <h2 [style.font-size]="pageWidth / 101 + 'px'">Team</h2>
        <h4 [style.font-size]="pageWidth / 106.6 + 'px'" class="p-0 m-0"
          *ngFor="let teamMember of selectedCompany?.team_members">
          {{ teamMember?.name }} - {{ teamMember?.position }}
        </h4>
      </div>
    </div>

    <div class="row">
      <h2 [style.font-size]="pageWidth / 101 + 'px'">Read their story</h2>
      <h3 [style.font-size]="pageWidth / 120 + 'px'" [innerHTML]="selectedCompany?.description"></h3>
    </div>
  </div>

  <div id="selectedVacancy" class="drawerFromBottom">
    <div class="closeArrow" (click)="closeVacancyPop()">
      <img src="assets/Images/closeArrow.png" />
    </div>

    <h1 *ngIf="this.inputString[1] == 'Portfolio vacancies'" [style.font-size]="pageWidth / 73.84 + 'px'">
      Portfolio vacancies
    </h1>
    <h1 *ngIf="this.inputString[1] == 'Algebra vacancies'" [style.font-size]="pageWidth / 73.84 + 'px'">
      Vacancies
    </h1>

    <div class="row row_div" *ngFor="let item of filterVacancies">
      <h2 [style.font-size]="pageWidth / 80 + 'px'" class="mt-2" *ngIf="item?.company">
        {{ item?.company }}
      </h2>
      <h2 [style.font-size]="pageWidth / 120 + 'px'">
        {{ item.position }}
        <a [href]="item.link" *ngIf="item?.link" class="link" target="_blank">
          Apply
        </a>
      </h2>
      <h1 [style.font-size]="pageWidth / 120 + 'px'">
        {{ item.description }}
      </h1>
    </div>

    <h2 class="email_h2">Email us at: people@algebraventures.com</h2>
  </div>
</div>