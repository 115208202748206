<div class="insta-bg">
  <div class="header-container">
    <img src="assets/Images/insta.svg" alt="" />
  </div>

  <div class="slider-container position-relative overflow-hidden">
    <div class="parent text-white">
      <a
        *ngFor="let data of instagramData; let i = index"
        href="{{ data.url }}"
        class="slider"
        target="_blank"
      >
        <div class="position-relative primary-image">
          <img src="{{ data.image }}" alt="" />
          <div class="position-absolute icon-container">
            <img
              src="assets/Images/instaIcon.svg"
              class="w-100 h-100"
              alt="instagrma icon"
            />
          </div>
        </div>
      </a>
    </div>
    <div class="arrows" *ngIf="instagramData.length > 4 || showArrows">
      <img
        src="assets/Images/rightArrow.svg"
        appNext
        class="left-arrow"
        alt=""
      />
      <img
        src="assets/Images/rightArrow.svg"
        appPrev
        class="right-arrow"
        alt=""
      />
    </div>
    <div class="line"></div>
    <div class="text-center mt-5">
      <a
        href="https://www.instagram.com/algebraventures/?igshid=MzRlODBiNWFlZA%3D%3D"
        class="text-white text-uppercase"
        >visit instagram</a
      >
    </div>
  </div>
</div>
