import { Platform } from '@angular/cdk/platform';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import Typewriter from 't-writer.js';
import { MainServicesService } from '../services/main-services.service';
import { ToastrServiceService } from '../services/toastr-service.service';

function sleep(ms) {
  return new Promise<void>((accept) => {
    setTimeout(() => {
      accept();
    }, ms);
  });
}

const windowHeight = Object.assign({});
@Component({
  selector: 'app-geek',
  templateUrl: './geek.component.html',
  styleUrls: ['./geek.component.scss'],
})
export class GeekComponent implements OnInit {
  @ViewChild('firm') firmDrawer: MatDrawer;

  inputDisableFlag = [true, true];
  pageHeight;
  pageWidth;
  inputString = ['', ''];
  unfliterdCompany = [];
  companies = [];
  companies1 = [];
  companies2 = [];
  members = [];
  selectedMembers;
  selectedMembersIndex;
  selectedCompany;
  selectedCompanyIndex;
  vacancies = [];
  firms = [];
  filterVacancies = [];
  homePageFlag = false;
  navTyped;
  isWriteHint = false;
  windowHeight;

  contactStep: string = null;

  subjects: any = [
    '---',
    'Submit a Pitch',
    'Job Inquiry',
    'Portfolio Contact',
    'Report a Grievance',
    'Other',
  ];
  contactObj: any = {
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  };

  initWriterObj: object = {
    loop: false,
    typeColor: '#fff',
    typeSpeed: 30,
    typeSpeedMax: 30,
    typeSpeedMin: 20,
  };
  isLoading: boolean = false;

  constructor(
    public sanitized: DomSanitizer,
    private _services: MainServicesService,
    private route: Router,
    public platform: Platform,
    private toastService: ToastrServiceService
  ) {
    this.pageHeight = window.innerHeight;
    this.pageWidth = window.innerWidth;
    this._services.getFirms().subscribe((res: any) => {
      this.firms = res;
    });
  }

  ngOnInit(): void {
    this.isWriteHint = true;

    document.addEventListener('DOMContentLoaded', this.windowReadyFun);
    let x = document.querySelector('.background') as HTMLElement;
    if (this.platform.IOS) {
      x.style.minHeight = '100vh';
    }
    this._services.getCompanies().subscribe((res: any) => {
      this.unfliterdCompany = res;
      this.companies = res;

      const half = Math.ceil(this.companies.length / 2);

      this.companies1 = this.companies.slice(0, half);
      this.companies2 = this.companies.slice(-half);
    });

    this._services.getMembers().subscribe((res: any) => {
      this.members = res;
    });

    this._services.getVacancies().subscribe((res: any) => {
      this.vacancies = res;
      this.filterVacancies = res;
    });

    const target = document.querySelector('.firstLine');
    const writer = new Typewriter(target, {
      loop: false,
      typeColor: 'white',
      typeSpeed: 30,
      typeSpeedMin: 20,
      typeSpeedMax: 30,
    });

    const target2 = document.querySelector('.secondLinePartOne');
    const writer2 = new Typewriter(target2, {
      loop: false,
      typeColor: 'white',
      typeSpeed: 30,
      typeSpeedMin: 20,
      typeSpeedMax: 30,
    });
    const target3 = document.querySelector('.secondLinePartTwo');
    const writer3 = new Typewriter(target3, {
      loop: false,
      typeColor: 'white',
      typeSpeed: 30,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });
    const target4 = document.querySelector('.secondLinePartThree');
    const writer4 = new Typewriter(target4, {
      loop: false,
      typeColor: '#fff',
      typeSpeed: 30,
    });

    writer
      .type(
        'We partner with bold and resilient founders building transformative companies'
      )
      .removeCursor()
      .then(() => {
        writer2
          .type('Type ')
          .removeCursor()
          .then(() => {
            writer3
              .changeTypeColor('#4E4BE4')
              .type('nav')
              .removeCursor()
              .then(() => {
                writer4
                  .changeTypeColor('#fff')
                  .type('>_ ')
                  .removeCursor()
                  .then(() => {
                    this.inputDisableFlag[0] = false;
                    document.getElementById('typeInput').focus();
                  })
                  .start();
              })
              .start();
          })
          .start();
      })
      .start();
  }

  windowReadyFun() {
    windowHeight.height = window.innerHeight;
  }

  onResize(event) {
    let currentHeight = event.target.innerHeight;
    if (currentHeight < 768) {
      if (currentHeight !== windowHeight.height) {
        let x = document.querySelector('.background') as HTMLElement;
        let heightString = windowHeight.height + 'px';
        x.style.minHeight = heightString;
      }
    }
  }

  keyDownFunction(event) {
    if (window.innerWidth < 768) {
      let x = document.querySelector('.background') as HTMLElement;
      if (this.platform.IOS) {
        x.style.minHeight = '100vh';
      }
    }

    if (event.keyCode === 13) {
      document.getElementById('typeInput1').blur();
      if (
        this.inputString[0].toLocaleLowerCase() == 'nav' &&
        !this.inputString[1] &&
        !this.homePageFlag
      ) {
        this.navClick();
        this.homePageFlag = true;
      } else if (this.inputString[1].toLocaleLowerCase() == 'firm') {
        this.delete();
        localStorage.setItem('selectedMainMenu', 'firm');
        this.firm();
      } else if (this.inputString[1].toLocaleLowerCase() == 'team') {
        this.delete();
        localStorage.setItem('selectedMainMenu', 'team');
        this.team();
      } else if (this.inputString[1].toLocaleLowerCase() == 'portfolio') {
        this.delete();
        localStorage.setItem('selectedMainMenu', 'portfolio');
        this.fundType();
      } else if (this.inputString[1].toLocaleLowerCase() == 'fund1') {
        this.delete();
        localStorage.setItem('selectedMainMenu', 'portfolio');
        this.portfolio('fund1');
      } else if (this.inputString[1].toLocaleLowerCase() == 'fund2') {
        this.delete();
        localStorage.setItem('selectedMainMenu', 'portfolio');
        this.portfolio('fund2');
      } else if (this.inputString[1].toLocaleLowerCase() == 'all') {
        this.delete();
        localStorage.setItem('selectedMainMenu', 'portfolio');
        this.portfolio('all');
      } else if (this.inputString[1].toLocaleLowerCase() == 'vacancies') {
        this.delete();
        localStorage.setItem('selectedMainMenu', 'vacancies');
        this.vacancie();
      } else if (this.inputString[1].toLocaleLowerCase() == 'contact') {
        this.delete();
        localStorage.setItem('selectedMainMenu', 'contact');
        this.contact();
      } else if (this.inputString[1].toLocaleLowerCase() == 'send') {
        this.delete();
        localStorage.setItem('selectedMainMenu', 'send');
        this.sendMessage();
      } else if (this.inputString[1].toLocaleLowerCase() == 'not a geek') {
        localStorage.setItem('selectedMainMenu', 'not a geek');
        this.route.navigate(['']);
      } else if (
        localStorage.getItem('selectedMainMenu') == 'team' &&
        this.isMember() &&
        this.inputString[1]
      ) {
        this.inputDisableFlag[1] = true;
        document
          .getElementById('selectedMember')
          .classList.add('drawerFromBottomShow');
      } else if (
        localStorage.getItem('selectedMainMenu') == 'portfolio' &&
        this.isProject() &&
        this.inputString[1]
      ) {
        this.inputDisableFlag[1] = true;
        document
          .getElementById('selectedCompany')
          .classList.add('drawerFromBottomShow');
      } else if (
        localStorage.getItem('selectedMainMenu') == 'vacancies' &&
        this.isVacancies() &&
        this.inputString[1]
      ) {
        this.inputDisableFlag[1] = true;

        if (
          this.inputString[1].toLocaleLowerCase() ===
            'Algebra vacancies'.toLocaleLowerCase() ||
          this.inputString[1].toLocaleLowerCase() === '1'
        ) {
          this.filterVacancies = this.vacancies.filter((x) => {
            return x.is_ours;
          });
        } else if (
          this.inputString[1].toLocaleLowerCase() ===
            'Portfolio vacancies'.toLocaleLowerCase() ||
          this.inputString[1].toLocaleLowerCase() === '2'
        ) {
          this.filterVacancies = this.vacancies.filter((x) => {
            return !x.is_ours;
          });

          let selected_elem = document.querySelector(
            '.email_h2'
          ) as HTMLElement;
          selected_elem.style.cssText = 'height:300px';
        }

        document
          .getElementById('selectedVacancy')
          .classList.add('drawerFromBottomShow');
      } else if (
        this.inputString[1].toLocaleLowerCase() == 'back' &&
        (this.contactStep == 'message' || this.contactStep == 'end')
      ) {
        this.displayContactSubjects('phone', this.inputString[1], 'subject');
      } else if (this.inputString[1].toLocaleLowerCase() == 'back') {
        document.getElementById('contactForm').innerHTML = '';
        document.getElementById('navs').innerHTML = '';
        this.delete();
        this.backToMenu2();
        let contactElement = document.querySelector(
          '.contact_case'
        ) as HTMLElement;
        contactElement.style.display = 'none';
      } else if (localStorage.getItem('selectedMainMenu') == 'contact') {
        let contactElement = document.querySelector(
          '.contact_case'
        ) as HTMLElement;
        contactElement.style.display = 'black';
      } else if (this.contactStep == 'name') {
        this.appendText('name', this.inputString[1], 'email');
      } else if (this.contactStep == 'email') {
        this.appendText('email', this.inputString[1], 'phone');
      } else if (this.contactStep == 'phone') {
        this.displayContactSubjects('phone', this.inputString[1], 'subject');
      } else if (this.contactStep == 'subject' && this.isSubject()) {
        document.getElementById('navs').innerHTML = '';
        this.appendText('subject', this.inputString[1], 'message');
      } else if (this.contactStep == 'message') {
        this.appendText('message', this.inputString[1], 'attachment');
      } else if (this.contactStep == 'end') {
        document.getElementById('contactForm').innerHTML = '';
        this.delete();
        localStorage.setItem('selectedMainMenu', 'contact');
        this.contact();
        this.contactUs();
      } else {
        this.showToast();
        let contactElement = document.querySelector(
          '.contact_case'
        ) as HTMLElement;
        contactElement.style.display = 'none';
      }
    }
  }

  isMember() {
    let x = this.members.filter((x, index) => {
      if (
        x.name.toLocaleLowerCase() == this.inputString[1].toLocaleLowerCase()
      ) {
        this.selectedMembers = x;
        return true;
      }

      if ((index + 1).toString() == this.inputString[1]) {
        this.selectedMembersIndex = index + 1;
        this.selectedMembers = x;
        return true;
      }
    });
    if (x.length > 0) return true;
    return false;
  }

  isProject() {
    let x = this.companies.filter((x, index) => {
      if (
        x.name.toLocaleLowerCase() == this.inputString[1].toLocaleLowerCase()
      ) {
        this.selectedCompany = x;
        return true;
      }
      if ((index + 1).toString() == this.inputString[1]) {
        this.selectedCompanyIndex = index + 1;
        this.selectedCompany = x;
        return true;
      }
    });
    if (x.length > 0) return true;
    return false;
  }

  isSubject() {
    let x = this.subjects.filter((x, index) => {
      let selectedSubject = x;
      let subject = this.inputString[1];
      if (selectedSubject.toLocaleLowerCase() == subject.toLocaleLowerCase()) {
        this.contactObj['subject'] = this.subjects[index];
        return true;
      }
      if ((index + 1).toString() == subject.toLocaleLowerCase()) {
        this.contactObj['subject'] = this.subjects[index];
        return true;
      }
    });
    if (x.length > 0) return true;
    return false;
  }

  isVacancies() {
    let vacans_arr = [
      { name: 'algebra vacancies' },
      { name: 'portfolio vacancies' },
    ];

    let x = vacans_arr.filter((x, index) => {
      if (
        x.name.toLocaleLowerCase() == this.inputString[1].toLocaleLowerCase()
      ) {
        return true;
      }
      if ((index + 1).toString() == this.inputString[1]) {
        return true;
      }
    });
    if (x.length > 0) return true;
    return false;
  }

  delete() {
    this.homePageFlag = false;
    this.inputString = ['', ''];
    this.inputDisableFlag = [true, true];
    const target = document.querySelector('.navOne');
    const target2 = document.querySelector('.navTwo');
    const target3 = document.querySelector('.navThree');
    const target4 = document.querySelector('.navFour');
    const target5 = document.querySelector('.navFive');
    const target6 = document.querySelector('.navSix');
    const target7 = document.querySelector('.navAnswerOne');
    const target8 = document.querySelector('.navAnswerTwo');
    const target9 = document.querySelector('.firstLine');
    const target10 = document.querySelector('.secondLinePartOne');
    const target11 = document.querySelector('.secondLinePartTwo');
    const target12 = document.querySelector('.secondLinePartThree');
    const target13 = document.querySelector('.nav0');
    const target14 = document.querySelector('.nav1');
    const target15 = document.querySelector('.nav4');
    const target16 = document.querySelector('.nav5');
    const target17 = document.querySelector('.nav6');
    const target18 = document.querySelector('.nav7');
    const target19 = document.querySelector('.nav8');
    const target20 = document.querySelector('.nav9');
    const target21 = document.querySelector('.nav10');
    const target22 = document.querySelector('.nav11');
    const target23 = document.querySelector('.nav3');
    const target24 = document.querySelector('.nav2');
    const targetMap = document.querySelector('.map_link');
    const targetMapSpan = document.querySelector('.map_span');
    const target35 = document.querySelector('.team0');
    const target36 = document.querySelector('.team1');
    const target34 = document.querySelector('.team2');
    const target33 = document.querySelector('.team3');
    const target25 = document.querySelector('.team4');
    const target26 = document.querySelector('.team5');
    const target27 = document.querySelector('.team6');
    const target28 = document.querySelector('.team7');
    const target29 = document.querySelector('.team8');
    const target30 = document.querySelector('.team9');
    const target31 = document.querySelector('.team10');
    const target32 = document.querySelector('.team11');
    const target44 = document.querySelector('.team12');
    const target43 = document.querySelector('.team13');
    const target45 = document.querySelector('.team14');
    const target46 = document.querySelector('.team15');
    const target37 = document.querySelector('.team16');
    const target38 = document.querySelector('.team17');
    const target39 = document.querySelector('.team18');
    const target40 = document.querySelector('.team19');
    const target41 = document.querySelector('.team20');
    const target42 = document.querySelector('.team21');
    const target47 = document.querySelector('.team22');
    const target48 = document.querySelector('.team23');
    const target49 = document.querySelector('.team24');
    const target50 = document.querySelector('.team25');
    const target51 = document.querySelector('.team26');
    const target52 = document.querySelector('.team27');
    this.isWriteHint = false;
    target ? (target.innerHTML = '') : null;
    target2 ? (target2.innerHTML = '') : null;
    target3 ? (target3.innerHTML = '') : null;
    target4 ? (target4.innerHTML = '') : null;
    target5 ? (target5.innerHTML = '') : null;
    target6 ? (target6.innerHTML = '') : null;
    target7 ? (target7.innerHTML = '') : null;
    target8 ? (target8.innerHTML = '') : null;
    target9 ? (target9.innerHTML = '') : null;
    target10 ? (target10.innerHTML = '') : null;
    targetMap ? (targetMap.innerHTML = '') : null;
    targetMapSpan ? (targetMapSpan.innerHTML = '') : null;
    target11 ? (target11.innerHTML = '') : null;
    target12 ? (target12.innerHTML = '') : null;
    target13 ? (target13.innerHTML = '') : null;
    target14 ? (target14.innerHTML = '') : null;
    target15 ? (target15.innerHTML = '') : null;
    target16 ? (target16.innerHTML = '') : null;
    target17 ? (target17.innerHTML = '') : null;
    target18 ? (target18.innerHTML = '') : null;
    target19 ? (target19.innerHTML = '') : null;
    target20 ? (target20.innerHTML = '') : null;
    target21 ? (target21.innerHTML = '') : null;
    target22 ? (target22.innerHTML = '') : null;
    target23 ? (target23.innerHTML = '') : null;
    target24 ? (target24.innerHTML = '') : null;
    target25 ? (target25.innerHTML = '') : null;
    target26 ? (target26.innerHTML = '') : null;
    target27 ? (target27.innerHTML = '') : null;
    target28 ? (target28.innerHTML = '') : null;
    target29 ? (target29.innerHTML = '') : null;
    target30 ? (target30.innerHTML = '') : null;
    target31 ? (target31.innerHTML = '') : null;
    target32 ? (target32.innerHTML = '') : null;

    target33 ? (target33.innerHTML = '') : null;
    target34 ? (target34.innerHTML = '') : null;
    target35 ? (target35.innerHTML = '') : null;
    target36 ? (target36.innerHTML = '') : null;
    target37 ? (target37.innerHTML = '') : null;
    target38 ? (target38.innerHTML = '') : null;

    // companies array
    target39 ? (target39.innerHTML = '') : null;
    target40 ? (target40.innerHTML = '') : null;
    target41 ? (target41.innerHTML = '') : null;
    target42 ? (target42.innerHTML = '') : null;
    target43 ? (target43.innerHTML = '') : null;
    target44 ? (target44.innerHTML = '') : null;
    target45 ? (target45.innerHTML = '') : null;
    target46 ? (target46.innerHTML = '') : null;
    target47 ? (target47.innerHTML = '') : null;
    target48 ? (target48.innerHTML = '') : null;
    target49 ? (target49.innerHTML = '') : null;
    target50 ? (target50.innerHTML = '') : null;
    target51 ? (target51.innerHTML = '') : null;
    target52 ? (target52.innerHTML = '') : null;
  }

  navClick() {
    this.inputDisableFlag[0] = true;
    localStorage.removeItem('selectedMainMenu');
    const target = document.querySelector('.navOne');
    const target2 = document.querySelector('.navTwo');
    const target3 = document.querySelector('.navThree');
    const target4 = document.querySelector('.navFour');
    const target5 = document.querySelector('.navFive');
    const target6 = document.querySelector('.navSix');
    const target7 = document.querySelector('.navAnswerOne');

    const writer = new Typewriter(target, {
      loop: false,
      typeColor: '#fff',
      typeSpeed: 40,
      typeSpeedMax: 40,
      typeSpeedMin: 20,
    });
    const writer2 = new Typewriter(target2, {
      loop: false,
      typeColor: '#fff',
      typeSpeed: 40,
      typeSpeedMax: 40,
      typeSpeedMin: 20,
    });
    const writer3 = new Typewriter(target3, {
      loop: false,
      typeColor: '#fff',
      typeSpeed: 40,
      typeSpeedMax: 40,
      typeSpeedMin: 20,
    });
    const writer4 = new Typewriter(target4, {
      loop: false,
      typeColor: '#fff',
      typeSpeed: 40,
      typeSpeedMax: 40,
      typeSpeedMin: 20,
    });
    const writer5 = new Typewriter(target5, {
      loop: false,
      typeColor: '#fff',
      typeSpeed: 40,
      typeSpeedMax: 40,
      typeSpeedMin: 20,
    });
    const writer6 = new Typewriter(target6, {
      loop: false,
      typeColor: '#fff',
      typeSpeed: 40,
      typeSpeedMax: 40,
      typeSpeedMin: 20,
    });
    const writer7 = new Typewriter(target7, {
      loop: false,
      typeColor: '#fff',
      typeSpeed: 30,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });
    const writer8 = new Typewriter(target7, {
      loop: false,
      typeColor: '#fff',
      typeSpeed: 30,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });

    // start back hint
    // const targetHintSp2 = document.querySelector('.hintSpn2');
    // const writerHintSp2 = new Typewriter(targetHintSp2, {
    //   loop: false,
    //   typeColor: 'white',
    //   typeSpeed: 30,
    //   typeSpeedMin: 20,
    //   typeSpeedMax: 30,
    // });

    // writerHintSp2
    //  .type('Type "Back" to return.')
    //   .removeCursor()
    //   .then(() => {
    writer
      .type('Firm')
      .removeCursor()

      .then(() => {
        writer2
          .type('Team')
          .removeCursor()
          .then(() => {
            writer3
              .type('Portfolio')
              .removeCursor()
              .then(() => {
                writer4
                  .type('Vacancies')
                  .removeCursor()
                  .then(() => {
                    writer5
                      .type('Contact')
                      .removeCursor()
                      .then(() => {
                        writer6
                          .type('not a geek')
                          .removeCursor()
                          .then(() => {
                            writer7
                              .changeTypeColor('#4E4BE4')
                              .type('Select')
                              .removeCursor()
                              .then(() => {
                                writer8
                                  .type('>_')
                                  .changeTypeColor('#fff')
                                  .removeCursor()
                                  .then(() => {
                                    this.inputDisableFlag[1] = false;
                                    document
                                      .getElementById('typeInput1')
                                      .focus();
                                  })
                                  .start();
                              })
                              .start();
                          })
                          .start();
                      })
                      .start();
                  })
                  .start();
              })
              .start();
          })
          .start();
      })
      .start();
    // })
    // .start();
  }

  backToMenu2() {
    this.isWriteHint = true;
    this.inputDisableFlag[0] = true;
    localStorage.removeItem('selectedMainMenu');
    const targetNav = document.querySelector(
      '.secondLinePartOne'
    ) as HTMLElement;
    const targetNav2 = document.querySelector(
      '.secondLinePartTwo'
    ) as HTMLElement;

    const targetNav3 = document.querySelector(
      '.secondLinePartThree'
    ) as HTMLElement;

    const target = document.querySelector('.navOne') as HTMLElement;
    const target2 = document.querySelector('.navTwo') as HTMLElement;
    const target3 = document.querySelector('.navThree') as HTMLElement;
    const target4 = document.querySelector('.navFour') as HTMLElement;
    const target5 = document.querySelector('.navFive') as HTMLElement;
    const target6 = document.querySelector('.navSix') as HTMLElement;
    const target7 = document.querySelector('.navAnswerOne');

    targetNav.innerHTML =
      'We partner with bold and resilient founders building transformative companies';
    targetNav2.innerHTML = 'Type nav>_ nav';
    // targetNav3.innerHTML = '> ';
    targetNav.style.color = '#fff';
    targetNav3.style.color = '#fff';
    targetNav2.style.cssText = 'color:#4E4BE4';
    target.innerHTML = 'Firm';
    target2.innerHTML = 'Team';
    target3.innerHTML = 'Portfolio';
    target4.innerHTML = 'Vacancies';
    target5.innerHTML = 'Contact';
    target6.innerHTML = 'not a geek';

    target.style.color = '#fff';
    target2.style.color = '#fff';
    target3.style.color = '#fff';
    target4.style.color = '#fff';
    target5.style.color = '#fff';
    target6.style.color = '#fff';
    const writer7 = new Typewriter(target7, {
      loop: false,
      typeColor: '#fff',
      typeSpeed: 30,
      typeSpeedMax: 30,
    });
    const writer8 = new Typewriter(target7, {
      loop: false,
      typeColor: '#fff',
      typeSpeed: 30,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });

    writer7
      .changeTypeColor('#4E4BE4')
      .type('Select')
      .removeCursor()
      .then(() => {
        writer8
          .type('>_')
          .removeCursor()
          .then(() => {
            this.inputDisableFlag[1] = false;
            document.getElementById('typeInput1').focus();
          })
          .start();
      })
      .start();
  }

  firm() {
    const target2 = document.querySelector('.secondLinePartOne');
    const writer2 = new Typewriter(target2, {
      loop: false,
      typeColor: 'white',
      typeSpeed: 30,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });
    const target3 = document.querySelector('.secondLinePartTwo');
    const writer3 = new Typewriter(target3, {
      loop: false,
      typeColor: 'white',
      typeSpeed: 30,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });
    const target4 = document.querySelector('.secondLinePartThree');
    const writer4 = new Typewriter(target4, {
      loop: false,
      typeColor: '#fff',
      typeSpeed: 30,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });
    writer2
      .changeTypeColor('#4E4BE4')
      .type('nav')
      .removeCursor()
      .then(() => {
        writer3
          .type('>firm>_on display...')
          .removeCursor()
          .then(() => {
            document
              .getElementById('drawerFromBottom')
              .classList.add('drawerFromBottomShow');
          })
          .start();
      })
      .start();
  }

  closeFirmPop() {
    // to add transition animation when click to close popup
    let element = document.getElementById('drawerFromBottom');
    if (element.classList.contains('drawerFromBottomShow')) {
      element.classList.add('showBackAnimate');
    }

    setTimeout(() => {
      element.classList.remove('drawerFromBottomShow');
      element.classList.remove('showBackAnimate');
      this.delete();
      this.backToMenu2();
    }, 2000);
  }

  closeTeamPop() {
    // to add transition animation when click to close popup
    let element = document.getElementById('selectedMember');
    if (element.classList.contains('drawerFromBottomShow')) {
      element.classList.add('showBackAnimate');
    }

    setTimeout(() => {
      element.classList.remove('drawerFromBottomShow');
      element.classList.remove('showBackAnimate');
      this.inputDisableFlag[1] = false;
      this.inputString[1] = '';
      document.getElementById('typeInput1').focus();
    }, 2000);
  }

  closeCompanyPop() {
    // to add transition animation when click to close popup
    let element = document.getElementById('selectedCompany');
    if (element.classList.contains('drawerFromBottomShow')) {
      element.classList.add('showBackAnimate');
    }

    setTimeout(() => {
      element.classList.remove('drawerFromBottomShow');
      element.classList.remove('showBackAnimate');
      this.inputDisableFlag[1] = false;
      this.inputString[1] = '';
      document.getElementById('typeInput1').focus();
    }, 2000);
  }

  closeVacancyPop() {
    // to add transition animation when click to close popup
    let element = document.getElementById('selectedVacancy');
    if (element.classList.contains('drawerFromBottomShow')) {
      element.classList.add('showBackAnimate');
    }

    setTimeout(() => {
      element.classList.remove('drawerFromBottomShow');
      element.classList.remove('showBackAnimate');
      let selected_elem = document.querySelector('.email_h2') as HTMLElement;
      selected_elem.style.cssText = 'height:0px';
      this.inputDisableFlag[1] = false;
      this.inputString[1] = '';
      document.getElementById('typeInput1').focus();
    }, 2000);
  }

  vacancie() {
    const target2 = document.querySelector('.secondLinePartOne');
    const writer2 = new Typewriter(target2, {
      loop: false,
      typeColor: 'white',
      typeSpeed: 30,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });
    const target3 = document.querySelector('.secondLinePartTwo');
    const writer3 = new Typewriter(target3, {
      loop: false,
      typeColor: 'white',
      typeSpeed: 30,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });
    const target4 = document.querySelector('.nav1');
    const target5 = document.querySelector('.nav3');

    const writerIndx4 = new Typewriter(target4, {
      loop: false,
      typeColor: '#4E4BE4',
      typeSpeed: 30,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });
    const writerIndx5 = new Typewriter(target5, {
      loop: false,
      typeColor: '#4E4BE4',
      typeSpeed: 30,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });

    const writer4 = new Typewriter(target4, {
      loop: false,
      typeColor: '#fff',
      typeSpeed: 30,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });
    const writer5 = new Typewriter(target5, {
      loop: false,
      typeColor: '#fff',
      typeSpeed: 30,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });

    writer2
      .changeTypeColor('#4E4BE4')
      .type('nav')
      .removeCursor()
      .then(() => {
        writer3
          .type('>vacancies>_on display…')
          .removeCursor()
          .then(() => {
            writerIndx4
              .type('1- ')
              .removeCursor()
              .then(() => {
                writer4
                  .type('Algebra vacancies')
                  .removeCursor()
                  .then(async () => {
                    writerIndx5
                      .type('2- ')
                      .removeCursor()
                      .then(async () => {
                        writer5
                          .type('Portfolio vacancies')
                          .removeCursor()
                          .then(() => {
                            const target7 =
                              document.querySelector('.navAnswerOne');
                            const writer7 = new Typewriter(target7, {
                              loop: false,
                              typeColor: '#fff',
                              typeSpeed: 30,
                              typeSpeedMax: 30,
                              typeSpeedMin: 20,
                            });
                            const writer8 = new Typewriter(target7, {
                              loop: false,
                              typeColor: '#fff',
                              typeSpeed: 30,
                              typeSpeedMax: 30,
                              typeSpeedMin: 20,
                            });
                            writer7
                              .changeTypeColor('#4E4BE4')
                              .type('Select')
                              .removeCursor()
                              .then(() => {
                                writer8
                                  .type('>_')
                                  .removeCursor()
                                  .then(() => {
                                    this.inputDisableFlag[1] = false;
                                    document
                                      .getElementById('typeInput1')
                                      .focus();
                                  })
                                  .start();
                              })
                              .start();
                          })
                          .start();
                        await sleep(500);
                      })
                      .start();
                    await sleep(500);
                  })
                  .start();
              })
              .start();
          })
          .start();
      })
      .start();
  }

  contact() {
    const target2 = document.querySelector('.secondLinePartOne');
    const writer2 = new Typewriter(target2, {
      loop: false,
      typeColor: 'white',
      typeSpeed: 20,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });
    const target3 = document.querySelector('.secondLinePartTwo');
    const writer3 = new Typewriter(target3, {
      loop: false,
      typeColor: 'white',
      typeSpeed: 20,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });
    const target4 = document.querySelector('.nav1');
    const targetMap = document.querySelector('.map_link') as HTMLElement;
    const targetMapSpan = document.querySelector('.map_span') as HTMLElement;
    const target5 = document.querySelector('.nav3');

    let contactElement = document.querySelector('.contact_case') as HTMLElement;
    contactElement.style.display = 'block';
    const writer4 = new Typewriter(target4, {
      loop: false,
      typeSpeed: 20,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
      typeColor: '#fff',
    });

    const writerMap = new Typewriter(targetMap, {
      loop: false,
      typeSpeed: 20,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
      typeColor: '#fff',
    });

    const writerMapSpan = new Typewriter(targetMapSpan, {
      loop: false,
      typeSpeed: 20,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
      typeColor: '#fff',
    });

    const writer5 = new Typewriter(target5, {
      loop: false,
      typeSpeed: 20,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
      typeColor: '#fff',
    });

    writer2
      .changeTypeColor('#4E4BE4')
      .type('nav')
      .removeCursor()
      .then(() => {
        writer3
          .type('>contact>_on display…')
          .removeCursor()
          .then(async () => {
            writer4
              .type(
                'We are located at 4 Omarat Al-Yamani Street, Zamalek, Cairo, Egypt.'
              )
              .removeCursor()
              .then(async () => {
                writerMapSpan
                  .type('Map Link: ')
                  .removeCursor()
                  .then(async () => {
                    writerMap
                      .type('https://goo.gl/maps/h2WiJ11H4VdaQf8DA')
                      .removeCursor()
                      .then(async () => {
                        writer5
                          .type('info@algebraventures.com')
                          .removeCursor()
                          .then(async () => {
                            this.typeInput('to send a message type', 'Send');
                          })
                          .start();
                        // await sleep(200);
                      })
                      .start();
                    // await sleep(200);
                  })
                  .start();
                // await sleep(200);
              })
              .start();
            // await sleep(200);
          })
          .start();
      })
      .start();
  }

  sendMessage() {
    this.inputDisableFlag[0] = true;
    localStorage.removeItem('selectedMainMenu');

    const target2 = document.querySelector('.secondLinePartOne') as HTMLElement;
    target2.style.display = 'block';
    const writer2 = new Typewriter(target2, this.initWriterObj);

    writer2
      .type('nav>contact>_Send a message…')
      .removeCursor()
      .then(() => {
        // this.typeInput(undefined, 'name')
        // this.contactStep = 'name'
        this.displayContactSubjects('phone', this.inputString[1], 'subject');
      })
      .start();
  }

  displayContactSubjects(name: string, value: string, nextStep: string) {
    this.inputDisableFlag[0] = true;
    localStorage.removeItem('selectedMainMenu');

    // let textWriter = this.writeText(`${name} : ${value}`, undefined, 'div', 'white') as Typewriter;

    this.subjects.forEach((subject: any, index: number) => {
      let subjectWriter = this.writeText(
        subject,
        'navs',
        undefined,
        undefined
      ) as Typewriter;
      setTimeout(() => {
        subjectWriter.start();
      }, 100 * index);
    });

    this.contactObj[name] = this.inputString[1];

    // textWriter.then(() => {
    this.inputString[1] = '';
    this.contactStep = nextStep;
    this.typeInput('Select', 'Subject');
    // }).start()
  }

  appendText(
    name: string,
    value: string,
    nextStep: string,
    element_selector: any = undefined
  ) {
    // let textWriter = this.writeText(`${name}: ${value}`, element_selector, 'div', "white") as Typewriter;
    this.contactObj[name] = this.inputString[1];
    // textWriter.then(() => {
    this.inputString[1] = '';
    this.contactStep = nextStep;
    if (nextStep == 'attachment') {
      document
        .getElementById('selectFile')
        .classList.add('drawerFromBottomShow');
      // this.appendText('attachment', this.inputString[1], 'message')
    } else {
      this.typeInput(undefined, nextStep);
    }
    // }).start()
  }

  writeText(
    type: string,
    element_selector: string = 'contactForm',
    element_type: string = 'div',
    color: string = 'white'
  ) {
    const node = this.appendNewChild(element_selector, element_type);
    const writer = new Typewriter(node, {
      ...this.initWriterObj,
      typeColor: color,
    });

    writer.type(type).removeCursor();
    return writer;
  }

  appendNewChild(element_selector: string, element_type: string) {
    const node = document.createElement(element_type);
    const text = document.getElementById(element_selector);
    text.appendChild(node);
    return node;
  }

  typeInput(first_text: string = 'Type', input: any) {
    this.inputDisableFlag[0] = true;
    const target = document.querySelector('.navAnswerOne');
    target.innerHTML = '';
    const target2 = document.querySelector('.navAnswerTwo');
    target2.innerHTML = '';

    let writer = new Typewriter(target, this.initWriterObj);
    let writer2 = new Typewriter(target2, this.initWriterObj);

    writer
      .type(first_text)
      .removeCursor()
      .then(() => {
        writer2
          .type(` ${input}>_`)
          .changeTypeColor('#4E4BE4')
          .removeCursor()
          .then(() => {
            this.inputDisableFlag[1] = false;
            document.getElementById('typeInput1').focus();
          })
          .start();
      })
      .start();
  }

  async team() {
    const target2 = document.querySelector('.secondLinePartOne');
    const writer2 = new Typewriter(target2, {
      loop: false,
      typeColor: 'white',
      typeSpeed: 30,
      typeSpeedMin: 20,
      typeSpeedMax: 30,
    });
    const target3 = document.querySelector('.secondLinePartTwo');
    const writer3 = new Typewriter(target3, {
      loop: false,
      typeColor: 'white',
      typeSpeed: 30,
      typeSpeedMin: 20,
      typeSpeedMax: 30,
    });

    writer2
      .changeTypeColor('#4E4BE4')
      .type('nav')
      .removeCursor()
      .then(() => {
        writer3
          .type('>team>_on display…')
          .removeCursor()
          .then(async () => {
            for (let i = 0; i < this.members.length; i++) {
              let target = document.querySelector('.nav' + i + '');
              let writer4 = new Typewriter(target, {
                loop: false,
                typeColor: 'white',
                typeSpeed: 30,
                typeSpeedMax: 30,
                typeSpeedMin: 20,
              });
              let writer5 = new Typewriter(target, {
                loop: false,
                typeColor: 'white',
                typeSpeed: 30,
                typeSpeedMax: 30,
                typeSpeedMin: 20,
              });
              writer4
                .changeTypeColor('#4E4BE4')
                .type('' + (i + 1) + '. ')
                .removeCursor()
                .then(() => {
                  writer5.type(this.members[i].name).removeCursor().start();
                })
                .start();
              // count += 1;
              await sleep(500);
            }
            const target7 = document.querySelector('.navAnswerOne');
            const writer7 = new Typewriter(target7, {
              loop: false,
              typeSpeed: 30,
              typeSpeedMax: 30,
              typeSpeedMin: 20,
              typeColor: '#fff',
            });
            const writer8 = new Typewriter(target7, {
              loop: false,
              typeSpeed: 30,
              typeSpeedMax: 30,
              typeSpeedMin: 20,
              typeColor: '#fff',
            });
            writer7
              .changeTypeColor('#4E4BE4')
              .type('Select')
              .removeCursor()
              .then(() => {
                writer8
                  .type('>_')
                  .removeCursor()
                  .then(() => {
                    this.inputDisableFlag[1] = false;
                    document.getElementById('typeInput1').focus();
                  })
                  .start();
              })
              .start();
          })
          .start();
      })
      .start();
  }

  filterCompanies(type) {
    if (type == 'all') {
      this.companies = this.unfliterdCompany;
    } else if (type == 'fund1') {
      this.companies = this.unfliterdCompany.filter((e) => {
        if (e.fund == 1) {
          return e;
        }
      });
    } else if (type == 'fund2') {
      this.companies = this.unfliterdCompany.filter((e) => {
        if (e.fund == 2) {
          return e;
        }
      });
    }
  }

  async portfolio(type) {
    this.filterCompanies(type);
    const target2 = document.querySelector('.secondLinePartOne');
    const writer2 = new Typewriter(target2, {
      loop: false,
      typeColor: 'white',
      typeSpeed: 30,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });
    const target3 = document.querySelector('.secondLinePartTwo');
    const writer3 = new Typewriter(target3, {
      loop: false,
      typeColor: 'white',
      typeSpeed: 30,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });

    writer2
      .changeTypeColor('#4E4BE4')
      .type('nav')
      .removeCursor()
      .then(() => {
        writer3
          .type('>portfolio>_on display…')
          .removeCursor()
          .then(async () => {
            for (let i = 0; i < this.companies.length; i++) {
              let target = document.querySelector(
                '.team' + i + ''
              ) as HTMLElement;
              target.innerHTML = ` ${'' + (i + 1) + '. '} ${
                this.companies[i].name
              }`;

              target.style.color = '#fff';
            }
            const target7 = document.querySelector('.navAnswerOne');
            const writer7 = new Typewriter(target7, {
              loop: false,
              typeSpeed: 30,
              typeSpeedMax: 30,
              typeSpeedMin: 20,
              typeColor: '#fff',
            });
            const writer8 = new Typewriter(target7, {
              loop: false,
              typeSpeed: 30,
              typeSpeedMax: 30,
              typeSpeedMin: 20,
              typeColor: '#fff',
            });
            writer7
              .changeTypeColor('#4E4BE4')
              .type('Select')
              .removeCursor()
              .then(() => {
                writer8
                  .type('>_')
                  .removeCursor()
                  .then(() => {
                    this.inputDisableFlag[1] = false;
                    document.getElementById('typeInput1').focus();
                  })
                  .start();
              })
              .start();
          })
          .start();
      })
      .start();
  }

  // array of team
  newArr(num) {
    return Array(Math.ceil(num));
  }

  // toastr fucntion
  showToast() {
    var myToastEl = document.getElementById('myToast');
    myToastEl.classList.add('show');
  }

  closeToast() {
    var myToastEl = document.getElementById('myToast');
    myToastEl.addEventListener('shown.bs.toast', function () {
      // do something...
      myToastEl.classList.remove('show');
    });
  }

  fundType() {
    const target2 = document.querySelector('.secondLinePartOne');
    const writer2 = new Typewriter(target2, {
      loop: false,
      typeColor: 'white',
      typeSpeed: 30,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });
    const target3 = document.querySelector('.secondLinePartTwo');
    const writer3 = new Typewriter(target3, {
      loop: false,
      typeColor: 'white',
      typeSpeed: 30,
      typeSpeedMax: 30,
      typeSpeedMin: 20,
    });

    writer2
      .changeTypeColor('#4E4BE4')
      .type('nav')
      .removeCursor()
      .then(() => {
        writer3
          .type('>portfolio>select fund version')
          .removeCursor()
          .then(async () => {
            let target1 = document.querySelector(
              '.team' + 0 + ''
            ) as HTMLElement;
            target1.innerHTML = `${'fund1'}`;
            target1.style.color = '#fff';
            let target2 = document.querySelector(
              '.team' + 1 + ''
            ) as HTMLElement;
            target2.innerHTML = ` ${'fund2'}`;
            target2.style.color = '#fff';
            let target3 = document.querySelector(
              '.team' + 2 + ''
            ) as HTMLElement;
            target3.innerHTML = `${'All'}`;
            target3.style.color = '#fff';

            const target7 = document.querySelector('.navAnswerOne');
            const writer7 = new Typewriter(target7, {
              loop: false,
              typeSpeed: 30,
              typeSpeedMax: 30,
              typeSpeedMin: 20,
              typeColor: '#fff',
            });
            const writer8 = new Typewriter(target7, {
              loop: false,
              typeSpeed: 30,
              typeSpeedMax: 30,
              typeSpeedMin: 20,
              typeColor: '#fff',
            });
            writer7
              .changeTypeColor('#4E4BE4')
              .type('Select')
              .removeCursor()
              .then(() => {
                writer8
                  .type('>_')
                  .removeCursor()
                  .then(() => {
                    this.inputDisableFlag[1] = false;
                    document.getElementById('typeInput1').focus();
                  })
                  .start();
              })
              .start();
          })
          .start();
      })
      .start();
  }

  closeSelectFile() {
    let element = document.getElementById('selectFile');
    if (element.classList.contains('drawerFromBottomShow')) {
      element.classList.add('showBackAnimate');
    }

    setTimeout(() => {
      element.classList.remove('drawerFromBottomShow');
      element.classList.remove('showBackAnimate');
      // if(this.contactObj.attachment != ''){
      this.appendText('attachment', this.contactObj.attachment?.name, 'end');
      // }else{
      //   document.getElementById("contactForm").innerHTML = ""
      //   this.delete();
      //   this.backToMenu2();
      // }
    }, 2000);
    // to add transition animation when click to close popup
  }

  getAttach(file: FileList) {
    this.contactObj.attachment = file.item(0);
  }

  contactUs() {
    this.isLoading = true;
    const formData = new FormData();
    let subject = this.contactObj.subject
      .split(' ')
      .map((item) => item[0].toUpperCase() + item.substring(1))
      .join(' ');
    formData.append('name', this.contactObj.name);
    formData.append('email', this.contactObj.email);
    formData.append('phone_number', this.contactObj.phone);
    formData.append('attachment', this.contactObj.attachment);
    formData.append('subject', subject);
    formData.append('message', this.contactObj.message);

    this._services.contactUs(formData).subscribe((res: any) => {
      this.isLoading = false;
    });
  }
}
