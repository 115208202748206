import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  pageHeight: number;
  pageWidth: number;
  mobile: boolean;

  constructor(private scroller: ViewportScroller, private router: Router) {
    this.pageHeight = window.innerHeight;
    this.pageWidth = window.innerWidth;

    if (this.pageWidth < 1000) {
      this.mobile = true;
    }
  }

  ngOnInit(): void {}

  goDown1(htmlElement) {
    if (htmlElement) htmlElement.close();
    this.router.navigate(['/']).then(() => {
      setTimeout(() => {
        this.scroller.scrollToAnchor('target1');
      }, 100);
    });
  }

  goDown2(htmlElement) {
    if (htmlElement) htmlElement.close();
    this.router.navigate(['/']).then(() => {
      setTimeout(() => {
        this.scroller.scrollToAnchor('target2');
      }, 100);
    });
  }

  goDown3(htmlElement) {
    if (htmlElement) htmlElement.close();
    this.router.navigate(['/']).then(() => {
      setTimeout(() => {
        this.scroller.scrollToAnchor('target3');
      }, 100);
    });
  }

  goDown4(htmlElement) {
    if (htmlElement) htmlElement.close();
    this.router.navigate(['/']).then(() => {
      setTimeout(() => {
        this.scroller.scrollToAnchor('target4');
      }, 100);
    });
  }

  goDown5(htmlElement) {
    if (htmlElement) htmlElement.close();
    this.router.navigate(['/']).then(() => {
      setTimeout(() => {
        this.scroller.scrollToAnchor('target5');
      }, 100);
    });
  }

  goDown6(htmlElement) {
    if (htmlElement) htmlElement.close();
    this.router.navigate(['/']).then(() => {
      setTimeout(() => {
        this.scroller.scrollToAnchor('blog');
      }, 100);
    });
  }

  goDown7(htmlElement) {
    if (htmlElement) htmlElement.close();
    this.router.navigate(['/']).then(() => {
      setTimeout(() => {
        this.scroller.scrollToAnchor('news');
      }, 100);
    });
  }

  openMobileHeader(htmlElement) {
    htmlElement.toggle();
  }
}
